var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', [_c('span', {
    staticClass: "text-secondary"
  }, [_vm._v(_vm._s(_vm.item.no))]), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Detail Pengembalian")]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sales"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.item.sales ? _vm.item.sales.nama_lengkap : _vm.item.id_sales))])])], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Request Pengembalian"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.humanDate(_vm.item.tanggal)))])])], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.item.keterangan))])])], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "4",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tujuan Pengembalian"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.item.gudang ? _vm.item.gudang.nama_gudang : _vm.item.id_gudang))])])], 1)], 1)], 1), _c('h3', [_vm._v("Rincian Barang "), _vm.item.rincian && _vm.item.rincian.length > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.item.rincian.length) + ")")]) : _vm._e()]), _c('b-card', [_c('b-table', {
    attrs: {
      "responsive": "",
      "striped": "",
      "bordered": "",
      "fields": _vm.fields,
      "items": _vm.item.rincian
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(_vm._s(++index))];
      }
    }, {
      key: "cell(nama)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.barang ? _c('span', [_vm._v(" " + _vm._s(item.barang.nama) + " ")]) : _c('strong', [_c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ditemukan")])])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.status && item.status.approve ? _c('feather-icon', {
          staticClass: "text-success",
          attrs: {
            "size": "24",
            "icon": "CheckCircleIcon"
          }
        }) : _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "size": "24",
            "icon": "XCircleIcon"
          }
        })];
      }
    }, {
      key: "cell(satuan)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.barang ? _c('span', [_vm._v(" " + _vm._s(item.barang.satuan ? item.barang.satuan.satuan : '-') + " ")]) : _c('strong', [_c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ditemukan")])])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref5) {
        var index = _ref5.index,
          item = _ref5.item,
          toggleDetails = _ref5.toggleDetails;
        return [_vm.isSales && _vm.myGudang.id ? _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "XCircleIcon",
            "size": "24"
          },
          on: {
            "click": function click($event) {
              return _vm.removeRincian(item, index);
            }
          }
        }) : _vm.isAdminGudang && !_vm.isSales && _vm.myGudang.id ? _c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return toggleDetails($event);
            }
          }
        }, [_vm._v("Pilih Stok")]) : _c('feather-icon', {
          staticClass: "text-secondary",
          attrs: {
            "icon": "SlashIcon",
            "size": "24"
          }
        })];
      }
    }, {
      key: "row-details",
      fn: function fn(_ref6) {
        var item = _ref6.item,
          toggleDetails = _ref6.toggleDetails;
        return [_c('b-card', [_c('b-row', {
          staticClass: "mb-2"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('table', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Gudang")]), _c('th', [_vm._v("Blok")]), _c('th', [_vm._v("Pallet")]), _c('th', [_vm._v("Rak")]), _c('th', [_vm._v("Laci")]), _c('th', [_vm._v("Stok")]), _c('th', [_vm._v("Stok Gudang")])]), _vm._l(item.stocks, function (stock, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(" " + _vm._s(stock.gudang ? stock.gudang.nama_gudang : '-') + " ")]), _c('td', [_vm._v(_vm._s(stock.blok ? "".concat(stock.blok.blok) : '-'))]), _c('td', [_vm._v(_vm._s(stock.palet ? "".concat(stock.palet.palet) : '-'))]), _c('td', [_vm._v(_vm._s(stock.rak ? "".concat(stock.rak.rak) : '-'))]), _c('td', [_vm._v(_vm._s(stock.laci ? "".concat(stock.laci.laci) : '-'))]), _c('td', [_vm._v(_vm._s(stock.stok))]), _c('td', [_c('validation-provider', {
            attrs: {
              "rules": "max_value:".concat(item.qty)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref7) {
                var errors = _ref7.errors;
                return [_c('b-form-input', {
                  attrs: {
                    "state": errors.length > 0 ? false : null
                  },
                  model: {
                    value: stock.requestedStock,
                    callback: function callback($$v) {
                      _vm.$set(stock, "requestedStock", $$v);
                    },
                    expression: "stock.requestedStock"
                  }
                }), _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])];
              }
            }], null, true)
          })], 1)]);
        })], 2)])], 1), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": toggleDetails
          }
        }, [_vm._v(" Hide ")])], 1)];
      }
    }])
  })], 1), _vm.isAdminGudang && !_vm.isSales ? _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.isApproved ? _c('b-button', {
    attrs: {
      "variant": "secondary",
      "disabled": ""
    }
  }, [_vm._v("Sudah Disetujui")]) : _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Setujui Pengembalian")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }